import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import { H2 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import BlokoText, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { useGetArticleHref } from 'src/components/ArticleItem/utils/get-article-properties';
import HumanDate from 'src/components/HumanDate';
import { useSelector } from 'src/hooks/useSelector';
import { BlogArticle } from 'src/models/blogPage';

import styles from './press-releases.less';

interface Props {
    item: BlogArticle;
}

const ANNOUNCE_MAX_LENGTH = 220;

const ArticleItemPressRelease: FC<Props> = ({ item }) => {
    const articleHref = useGetArticleHref(item);
    const pressReleaseData = useSelector(({ blogPage }) => blogPage.pressReleaseData);

    return (
        <div className={styles.pressReleaseItem}>
            <div>
                <div className={styles.pressReleaseTitle}>
                    <BlokoLink kind={LinkKind.Tertiary} disableVisited Element={Link} to={articleHref}>
                        <H2>{item.title}</H2>
                    </BlokoLink>
                </div>
                <VSpacing base={4} />
                <div className={styles.pressReleaseAnnounce}>
                    {item.announce && item.announce.length > ANNOUNCE_MAX_LENGTH
                        ? `${item.announce.substring(0, ANNOUNCE_MAX_LENGTH)}...`
                        : item.announce}
                </div>
                <VSpacing base={4} />
            </div>
            <div className={styles.pressReleaseFooter}>
                <VSpacing base={4} />
                {!!pressReleaseData[item.employerId] && (
                    <>
                        <Link
                            target="_blank"
                            to={
                                pressReleaseData[item.employerId].url
                                    ? pressReleaseData[item.employerId].url
                                    : `/employer/${item.employerId}`
                            }
                            inline
                        >
                            {pressReleaseData[item.employerId].companyName}
                        </Link>
                        <BlokoText Element="span" importance={TextImportance.Tertiary}>
                            <span className={styles.pressReleaseDot}>•</span>
                        </BlokoText>
                    </>
                )}
                <BlokoText Element="span" importance={TextImportance.Tertiary}>
                    <HumanDate date={item.publicationDate} />
                </BlokoText>
            </div>
        </div>
    );
};

export default ArticleItemPressRelease;
