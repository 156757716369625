import { useMemo } from 'react';

import { urlParser } from '@hh.ru/browser-api-utils';

const useLinkWithUTMParams = (projectUrl: string): string => {
    return useMemo(() => {
        const parsedUrl = urlParser(projectUrl);

        parsedUrl.params.utm_source ??= 'hh'; // eslint-disable-line camelcase
        parsedUrl.params.utm_medium ??= 'referral'; // eslint-disable-line camelcase
        parsedUrl.params.utm_campaign ??= 'blog_hh'; // eslint-disable-line camelcase

        return parsedUrl.href;
    }, [projectUrl]);
};

export default useLinkWithUTMParams;
